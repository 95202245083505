<template>
    <section class="box">
        <AdminUsersTable></AdminUsersTable>
    </section>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import ColumnEditable from '@/components/Admin/Sites/ColumnEditable.vue'
    import TrueFalseBadge from '@/components/UI/TrueFalseBadge.vue'
    import AdminUsersTable from '@/components/Admin/User/AdminUsersTable.vue'

    export default {
        name: 'UsersView',
        title: 'Utilisateurs',
        components: { AdminUsersTable, TrueFalseBadge, ColumnEditable, NlTable },

        data() {
            return {
                forceUpdate: 1
            }
        },
        methods: {}
    }
</script>
